/*--------------------------------------------------------------
# Illustrations
--------------------------------------------------------------*/
@import 'illustrations/illustrations';

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/
@import 'base/fonts';

/*--------------------------------------------------------------
# Scroll reveal
--------------------------------------------------------------*/
@import 'base/scroll-reveal';

/*--------------------------------------------------------------
# Base
--------------------------------------------------------------*/
@import 'base/base';

/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
@import 'base/typography';

/*--------------------------------------------------------------
# Containers
--------------------------------------------------------------*/
@import 'elements/containers';

/*--------------------------------------------------------------
# Buttons
--------------------------------------------------------------*/
@import 'elements/buttons';

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
@import 'elements/forms';

/*--------------------------------------------------------------
# Hamburger
--------------------------------------------------------------*/
@import 'elements/hamburger';

/*--------------------------------------------------------------
# Modal
--------------------------------------------------------------*/
@import 'elements/modal';

/*--------------------------------------------------------------
# Split pattern
--------------------------------------------------------------*/
@import 'patterns/split';

/*--------------------------------------------------------------
# Tiles pattern
--------------------------------------------------------------*/
@import 'patterns/tiles';

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
@import 'layout/header';

/*--------------------------------------------------------------
# Site content
--------------------------------------------------------------*/
@import 'layout/main';

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
@import 'layout/footer';

/*--------------------------------------------------------------
# Section
--------------------------------------------------------------*/
@import 'sections/section';

/*--------------------------------------------------------------
# Hero
--------------------------------------------------------------*/
@import 'sections/hero';

/*--------------------------------------------------------------
# Features split
--------------------------------------------------------------*/
@import 'sections/features-split';

/*--------------------------------------------------------------
# Features tiles
--------------------------------------------------------------*/
@import 'sections/features-tiles';

/*--------------------------------------------------------------
# Testimonial
--------------------------------------------------------------*/
@import 'sections/testimonial';

/*--------------------------------------------------------------
# Call to action
--------------------------------------------------------------*/
@import 'sections/cta';

.banner {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  z-index: 60;

  @include media('>medium') {
    bottom: 32px;
    right: 48px;
    width: auto;
  }
}

.banner-inner {
  background-color: #1e293b;
  color: #f8fafc;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include media('>medium') {
    border-radius: 4px;
  }
}

.banner-container {
  display: inline-flex;
  color: #64748b;
}

.banner-link {
  font-weight: 500;

  &:hover {
    text-decoration: underline;
  }
}

.banner-link-white {
  color: #f8fafc !important;
}

.banner-link-green {
  color: #10b981 !important;
}

.banner-separator {
  font-style: italic;
  padding: 0 6px;
}

.banner-hide-on-sm {
  display: none;

  @include media('>small') {
    display: inline;
  }
}

.banner-close {
  background: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  color: #64748b;
  padding-left: 8px;
  margin-left: 12px;
  border-left: 1px solid #334155;

  &:hover {
    color: #94a3b8;
  }

  svg {
    width: 16px;
    height: 16px;
    flex-shrink: 0;
    fill: currentColor;
  }
}
