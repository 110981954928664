// Modal (core/elements/_modal.scss)

// Background color
$modal--bg: (
  modal: get-color(dark, 2),
  modal-outer: rgba(get-color(dark, 1), 0.88),
);

// More modal settings
$modal--min-width: null; // modal min-width
$modal--max-width: 520px; // modal max-width
$modal-video--max-width: 1024px; // modal video max-width
$modal--outer-padding-v__mobile: 16px; // modal, outer top and bottom padding (mobile)
$modal--outer-padding-v__desktop: 48px; // modal, outer top and bottom padding (desktop)
$modal--inner-padding-v__mobile: 48px; // modal, inner top and bottom padding (mobile)
$modal--inner-padding-h__mobile: 16px; // modal, inner left and right padding (mobile)
$modal--inner-padding-v__desktop: 64px; // modal, inner top and bottom padding (desktop)
$modal--inner-padding-h__desktop: 32px; // modal, inner left and right padding (desktop)
$modal-close-icon--size: 16px; // modal close icon, width and height
$modal-close-icon--thickness: 2px; // modal close icon, stroke width
$modal-close-icon--radius: null; // modal close icon, lines radius cap
$modal-close-position: 16px; // icon distance from the top right of the modal

// Don't change line below!
$bg--color: map-push($bg--color, $modal--bg);
