@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system,
  BlinkMacSystemFont,
  Segoe UI,
  Roboto,
  Oxygen,
  Ubuntu,
  Cantarell,
  Fira Sans,
  Droid Sans,
  Helvetica Neue,
  sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

/*MAIN*/

:root {
  --heading-font: 'Poppins', sans-serif;
  --main-font: 'Poppins', sans-serif;

  --fs1: 12px;
  --fs2: 14px;
  --fs3: 16px;
  --fs4: 18px;
  --fs5: 20px;
  --fs6: 24px;
  --fs7: 30px;
  --fs8: 36px;
  --fs9: 48px;
  --fs10: 60px;
  --fs11: 72px;

  --sp1: 4px;
  --sp2: 8px;
  --sp3: 12px;
  --sp4: 16px;
  --sp5: 24px;
  --sp6: 32px;
  --sp7: 48px;
  --sp8: 64px;
  --sp9: 96px;
  --sp10: 128px;

  /* COLORS */

  /*--background-plain-color: #F9F7F6;*/
  --main-gradient-cta-button: linear-gradient(#ff5f6d, #fcae51);

  --main-header-color: white;
  --main-text-color: #dcdada;
  --header2-main-section: #dcdada;
  --feature-section-header-color: #dcdada;
}

.color-basic-text {
  color: #dcdada;
}

* {
  margin-block: 0px;
  box-sizing: border-box;
  line-height: 1.5;
}

*::placeholder {
  /*color: var(--neutr4);*/
}

html {
  font-size: var(--fs3);
}

body {
  font-family: var(--main-font), sans-serif;
  margin: 0px;
}

h1,
h2 {
  font-family: var(--heading-font), serif;
}

h1 {
  font-size: var(--fs9);
  line-height: 1.2;
}

h2 {
  font-size: var(--fs7);
}

h3 {
  font-size: 1.5rem;
}

ul {
  padding-inline-start: 2rem;
}

.hidden {
  display: none !important;
}

button {
  border: 0px;
  /*padding: 8px 24px;*/
  border-radius: 10px;
  font-weight: bold;
  font-size: 1rem;
  white-space: nowrap;
  transition: all 0.05s ease-in-out;
  cursor: pointer;
}

button:hover {
  filter: brightness(1.2);
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  color: #0000003f;
}

/* HEADER */

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  width: 100%;
  min-height: 80vh;
}

.header-title {
  font-size: var(--fs9);
  color: var(--main-header-color);
}

.header-column {
  display: flex;
  flex-direction: column;
  gap: var(--sp6);
  flex: 1;
  max-width: 500px;
  /* min-width: 400px; */
  align-items: flex-start;
  perspective: 1000px;
}

.header-subtitle {
  color: var(--header2-main-section);
  padding-left: 2px;
}

.header-list {
  font-size: var(--fs3);
  padding-left: 0em;
  list-style-type: none;
}

.header-list li .fas {
  margin-right: 10px;
  color: var(--main-text-color);
}

.header-column.header-content {
  max-width: 500px;
}

.header-media-content {
  max-width: 100%;
  border-radius: 10px;
  box-shadow: rgba(248, 0, 36, 0.32) 0 0 6px 5px;
  transform: rotateY(344deg);
}

.header-media-mobile {
  display: none;
  max-width: 90%;
  box-shadow: rgba(248, 0, 36, 0.32) 0 0 6px 5px;
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  h1 {
    font-size: 2.4em;
  }

  .header {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .header-title {
    font-size: var(--fs7);
  }

  .header-column {
    align-items: center;
  }

  .header-media {
    display: none;
  }

  .header-media-mobile {
    display: block;
  }
}

/*CHAT*/

@media screen and (max-width: 900px) {
  h1 {
    font-size: 2.4em;
  }
}

@keyframes dots {
  0%,
  20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60% {
    content: '...';
  }
  100% {
    content: '.';
  }
}

.typing-dots::after {
  content: '';
  display: inline-block;
  animation: dots 2s steps(5, end) infinite;
}

/*MSG COUNTER */

.center-content {
  justify-content: center;
}

.msg-counter-value {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

/*SIGN IN*/
.container .cl-internal-15amzqo {
  color: rgb(16, 63, 239);
}

@keyframes glowing {
  0% {
    box-shadow: 0 0 5px #44BCFF, 0 0 10px #FF44EC, 0 0 15px #FF675E;
  }
  50% {
    box-shadow: 0 0 10px #44BCFF, 0 0 20px #FF44EC, 0 0 25px #FF675E;
  }
  100% {
    box-shadow: 0 0 5px #44BCFF, 0 0 10px #FF44EC, 0 0 15px #FF675E;
  }
}

.glow-animation {
  display: inline-block;
  padding: 8px 16px;
  position: relative;
  z-index: 1;
  color: white;
  background: none;
  border:0 solid transparent;
  box-shadow: 0 0 5px #44BCFF, 0 0 10px #FF44EC, 0 0 15px #FF675E;
  animation: glowing 1.5s infinite alternate;
  border-radius: 8px;
}

.text-highlight{
  color: #2975cc
}

/*BANNER*/
.bannerModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  width: 90%;
  max-width: 400px;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  text-align: center; /* Center content */

  animation: bannerFadeIn 0.3s ease-out;

  z-index: 1000;
}

.bannerImage {
  max-width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.bannerHeader {
  color: black;
  font-size: 24px;
  margin-bottom: 5px;
}

.bannerText {
  color: #222;
  font-size: 20px;
  margin-bottom: 20px;
}

.bannerButton {
  padding: 10px 20px;
  background-color: #AC1E2A; /* or any desired color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;

  &:hover {
    background-color: rgba(255, 79, 102, 0.78);; /* slightly darker for hover effect */
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Black with 70% opacity */
  backdrop-filter: blur(4px);

  z-index: 999; /* Make sure it's below the modal (bannerModal has z-index: 1000) */

  animation: bannerFadeIn 0.3s ease-out;
}

@keyframes bannerFadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body.modal-open {
  overflow: hidden;
}
