h3,
h4,
h5,
h6,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 700;
}
